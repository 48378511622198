import React, { Component } from 'react';
import { Link } from "react-scroll";

export default class Navbar extends Component {
      // constructor(){
      //       super();
      // }

      componentDidMount() {
            window.addEventListener("scroll", this.handleScroll);
            }
            
            componentWillUnmount() {
            window.removeEventListener("scroll", this.handleScroll);
            }
            
            handleScroll = () => {
            if (window.scrollY > 20) {
                  document.querySelector(".nbar").className = "nbar scroll";
            } else {
                  document.querySelector(".nbar").className = "nbar";
            }};

    render() {
        return(

       <div>
        <nav className="nbar">
          <ul>
            <li><Link 
                  spy={true}
                  smooth={true}
                  //offset={-40}
                  duration={300} 
                  to='about-us'>ABOUT US</Link></li>
            <li><Link 
                  spy={true}
                  smooth={true}
                  //offset={-40}
                  duration={300}
                   to='what-we-do'>WHAT WE DO</Link></li>
            <li><Link 
                  spy={true}
                  smooth={true}
                  //offset={-40}
                  duration={300} 
                  to='our-approach'>OUR APPROACH</Link></li>
            <li><Link 
                  spy={true}
                  smooth={true}
                  //offset={-40}
                  duration={300} 
                  to='our-team'>OUR TEAM</Link></li>
            <li><Link 
                  spy={true}
                  smooth={true}
                  //offset={-40}
                  duration={300} 
                  to='contact-us'>CONTACT US</Link></li>
          </ul>
        </nav>
       </div>

        )
      }
}
