import React from 'react';
import Image from 'react-bootstrap/Image';
import Slide from '../assets/logo-white.png';
import DownArrow from '../assets/down-arrow.svg';
import { Link } from "react-scroll";
import { Container, Jumbotron } from 'react-bootstrap';

const Hero = ({ imageUrl }) => (

  <Jumbotron fluid style={{ backgroundImage: `url(${imageUrl})` }} className="header mb-0">
    <Container>
      <div className="logo text-center">
        <Image width="400px" height="100%" className="img-fluid" src={Slide} alt="logo" />
        <p className="lead text-white text-center">Your Trusted Partner in Wealth</p>
      </div>
        <Link className="down-arrow-link"
          spy={true}
          smooth={true}
          offset={-40}
          duration={300}
          to='about-us'>
          <Image width="50px" height="100%" className="down-arrow" src={DownArrow} alt="scroll down" />
        </Link>
    </Container>
  </Jumbotron>

);

export default Hero