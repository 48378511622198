import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AboutUs = ( {content} ) => (

          <Container fluid id="about-us" className="about-background about container-padding">
            <Container>
                <Row>
                  <Col md={4}><h2 className="title">ABOUT US</h2></Col>
                  <Col md={8}>
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                  </Col>
                </Row>
            </Container>
          </Container>
);
export default AboutUs;