import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';


const ModalPopup = (props) => {


  const [showModal, setShowModal] = useState();

  useEffect(() => {

    setShowModal(props.show);
  }, [props.show])

  const handleClose = () => {
    // setShowModal(false);
    if(typeof props.handleOnExited !== "undefined"){
      props.close();
    }
  }

  const handleOnExited = () => {
    if(typeof props.handleOnExited !== "undefined"){
      props.handleOnExited();
    }
   

  }

  return (

    <Modal dialogClassName="border-radius-2" show={showModal} onHide={handleClose} onExited={handleOnExited} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: props.children }}></div>
      </Modal.Body>

    </Modal >


  )
}

export default ModalPopup



