import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class OurApproach extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      surname: '',
      email: '',
      number: '',
      message: '',
      form_success: ''
    }
  }
  handleSubmit(event) {
    event.preventDefault();
    var formdata = new FormData();
    formdata.append("your-name", this.state.name);
    formdata.append("your-surname", this.state.surname);
    formdata.append("your-email", this.state.email);
    formdata.append("phone", this.state.number);
    formdata.append("your-message", this.state.message);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://pwmsa.co.za/backend/wp-json/contact-form-7/v1/contact-forms/38/feedback/", requestOptions)
    .then(response => response.text())
    .then(
      (result) => {
         const resOb = JSON.parse(result);
         console.log(resOb);
          this.setState({form_success: resOb.message});
      }
    )
    .catch(error => console.log('error', error));
  }

  resetForm() {
    this.setState({ name: '', surname: '', number: '', email: '', message: '' })
  }

  render() {
    return (



      <Container id="contact-us" fluid className="contact-background headed container-padding">
        <Container>
          <Row className="mb-4">
            <Col>
              <h2 className="title">CONTACT US</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={8} className="contact-form1 mb-4">
              {
                this.state.form_success ?
                <div className="complete-message">
                  <p>{this.state.form_success}</p>
                </div>
                :
                <div>
                 
                  <Form className="default-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                    <Form.Group as={Row} className="mb-0">

                      <Col md={6} className="form-group">
                        <Form.Control className="border-0" type="text" placeholder="Name" required value={this.state.name} onChange={this.onNameChange.bind(this)} />
                      </Col>
                      <Col md={6} className="form-group">
                        <Form.Control className="border-0" type="text" placeholder="Surname" required value={this.state.surname} onChange={this.onSurnameChange.bind(this)} />

                      </Col>


                      <Col md={6} className="form-group">
                        <Form.Control className="border-0" type="email" placeholder="Email address" required value={this.state.email} onChange={this.onEmailChange.bind(this)} />

                      </Col>



                      <Col md={6} className="form-group">
                        <Form.Control className="border-0" type="text" placeholder="Contact number" value={this.state.number} onChange={this.onNumberChange.bind(this)} />


                      </Col>
                      <Col md={12} className="form-group">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          {/* <Form.Label>Example textarea</Form.Label> */}
                          <Form.Control as="textarea" rows={3} placeholder="Your message" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
                        </Form.Group>
                      </Col>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                      SEND
                    </Button>

                  </Form>
                </div>
              }
              
            </Col>
            <Col sm={6} md={4} >
              <div className="contact-form">
                <h4 className="mb-3"><strong>OUR OFFICES</strong></h4>
                <div>
                  <p className="styled" dangerouslySetInnerHTML={{ __html: this.props.our_offices }}></p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

    )
  }

  onNameChange(event) {
    this.setState({ name: event.target.value })
  }

  onSurnameChange(event) {
    this.setState({ surname: event.target.value })
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  onNumberChange(event) {
    this.setState({ number: event.target.value })
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value })
  }
}
