import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';




const WhatWeDo = ({ content }) => (

  <Container fluid id="what-we-do" className="light-grey-background container-padding">
    <Container>
      <Row >
        <Col md={4}><h2 className="title" >WHAT WE DO</h2></Col>
        <Col md={8}>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </Col>
      </Row>
    </Container>
  </Container>

);
export default WhatWeDo;

