// import logo from './logo.svg';
import React, { useState, useEffect } from 'react';

import Navbar from './components/Navbar';
import Hero from './components/Hero';
import AboutUs from './components/AboutUs.jsx';
import WhatWeDo from './components/WhatWeDo';
import OurApproach from './components/OurApproach';
import OurTeam from './components/OurTeam';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';

function App() {
  const [homedata, setHomeData] = useState(null);


  useEffect(() => {
    fetch('https://pwmsa.co.za/backend/wp-json/pn/v1/homepage/')
    .then(response => response.json())
    .then( data => setHomeData(data) );
  
  }, []);

  return (
    <div className="App">
          <Navbar/>
          <Hero  imageUrl={ homedata !== null ? homedata.hero_img_url : ""} />
          <AboutUs content={ homedata !== null ? homedata.about_us : ""} />
          <WhatWeDo  content={ homedata !== null ? homedata.what_we_do : ""} />
          <OurApproach approaches={ homedata !== null ? homedata.approaches : []} />
          <OurTeam team={ homedata !== null ? homedata.team : []}/>
          <ContactUs  our_offices={ homedata !== null ? homedata.our_offices : ""}/>
          <Footer 
           
            footer_bottom={ homedata !== null ? homedata.footer_bottom : ""}
          />
    </div>
  );
}

export default App;
