import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const OurApproach = ({ approaches }) => (



  <Container fluid id="our-approach" className="container-padding">
    <Container>
      <Row>
        <Col md={4}>
          <h2 className="title" >OUR APPROACH</h2>
        </Col>
      </Row>
      <Row as="ol" className="p-0 mx-3">
        {
          approaches.map((approach, index) => (
            <li key={index} className="mt-md-5">
              <div className="approach-item">
                <h4>{approach.post_title}</h4>
                <div dangerouslySetInnerHTML={{ __html: approach.post_content }}></div>
              </div>
            </li>
          ))
        }
      </Row>
    </Container>
  </Container>


);

export default OurApproach;