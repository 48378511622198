import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const OurTeam = ({ team }) => (

            <Container fluid id="our-team" className="team-background team container-padding">
                <Container>
                    <Row>
                        <Col>
                            <h2 className="title text-white" >OUR TEAM</h2>
                        </Col>
                    </Row>

                    <Row>
                        {
                            team.map((member) => (

                                <Col lg="4" className="mt-4" key={member.id}>
                                    <h5 className="text-white">{member.name}, {member.education}</h5>
                                    <p>{member.position}
                                        <br />
                                        <span className="adjust">
                                            {member.email} <br />
                                            {member.phone}
                                        </span>
                                    </p>
                                </Col>
                            ))
                        }
                        
                    </Row>
                </Container>
            </Container>

);

export default OurTeam;
