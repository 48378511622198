import React, { useState } from 'react';

import ModalPopup from "./ModalPopup";

const Footer = ({ footer_bottom}) => {

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupContent, setpopupContent] = useState("loading...");
  const [popupTitle, setpopupTitle] = useState("");
  
  function openPopup(e) {
    e.preventDefault();    
    const pId = e.target.getAttribute('data-page') ?  e.target.getAttribute('data-page') : 0;

    if(pId > 0){
      setPopupVisible(true);


        fetch('https://pwmsa.co.za/backend/wp-json/wp/v2/pages/'+pId)
      .then(response => response.json())
      .then( data => {
        setpopupContent(data.content.rendered);
        setpopupTitle(data.title.rendered);
      } );
        
   
      

    }
    
  }

  function closePopup(){
    setPopupVisible(false);

  }

  function handleOnExited(){
    setpopupContent("loading...");
    setpopupTitle("");
  }

  return (
     
      <div className="footer">
        <p><span dangerouslySetInnerHTML={{ __html: footer_bottom }}></span>
        <span className="floar"><a className="modals" href="#open-popup" data-page="3" onClick={openPopup}>Legal</a></span></p>

        <ModalPopup handleOnExited={handleOnExited} close={closePopup} title={popupTitle} show={popupVisible} >{popupContent}</ModalPopup>
      </div>
      
    
  )
}
export default Footer;
